import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import Tags from '../../../common/components/tags';
import { getTagCloudWidgetData } from '../../aggregated-tag-cloud/selectors';

const TagCloud = ({ tagCloudWidget }) => (
  <Tags tags={Object.values(tagCloudWidget.tags)} translations={tagCloudWidget} />
);

TagCloud.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
};

const mapRuntimeToProps = (state) => ({
  tagCloudWidget: getTagCloudWidgetData(state),
});

export default connect(mapRuntimeToProps)(TagCloud);
